var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            ((_vm.$route.meta.title) + " " + (_vm.loading ? '' : ("(" + (_vm.table_data.length) + ")")))
          )}})]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[(_vm.login.permissions.faqs.create)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"success","to":{ name: (_vm.page_route + ".store") }}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-plus')}})],1)]}}],null,false,484078399)},[_c('span',{domProps:{"textContent":_vm._s('Crear')}})]):_vm._e()],1),(_vm.table_data.length > 0)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.table_search),callback:function ($$v) {_vm.table_search=$$v},expression:"table_search"}})],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.table_headers,"search":_vm.table_search,"items":_vm.table_data,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
          var item = ref.item;
return [_c('b',{domProps:{"textContent":_vm._s(item.key)}})]}},{key:"item.url_video",fn:function(ref){
          var item = ref.item;
return [(item.url_video)?_c('v-btn',{attrs:{"icon":"","small":"","color":"success","href":item.url_video,"target":"_blank"}},[_c('v-icon',[_vm._v(" mdi-video-check")])],1):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-video-off ")])]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"primary","to":{
                    name: (_vm.page_route + ".view"),
                    params: { id: item.id },
                  }}},on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Ver')}})])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }